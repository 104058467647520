// 電腦版 滑出 手機板 點擊
// .point(style="top: ''; left: '' title='' data-toggle="popover" data-placement="top" tabindex="0" data-trigger="focus" data-content="")
$(document).ready(function(){
  var is_touch_device = ("ontouchstart" in window) || window.DocumentTouch && document instanceof DocumentTouch;
  $("[data-toggle='popover']").popover({
      trigger: is_touch_device ? "click" : "hover"
  });
});

// CSS
// @keyframes neon2 {
//   0% {
//     background-color: #fff;
//   }
//   50% {
//     box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px red, 0 0 40px red, 0 0 70px red;
//   }
//   100% {
//     background-color: #fff;
//   }
// }

// .point{
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 2;
//
//   &:before{
//     content: '';
//     z-index: 2;
//     display: block;
//     width: 20px;
//     height: 20px;
//     border-radius: 30px;
//     animation: neon2 0.78s ease infinite;
//     background-color: #fff;
//     margin:  auto;
//     box-shadow: 0 0 5px #fff, 0 0 10px red, 0 0 15px red, 0 0 20px red, 0 0 35px red;
//   }
//
// }
